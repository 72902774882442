<template>
  <div>
    <el-dialog
      :title="item.id?'编辑物资':'新增物资'"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="60%"
      :close-on-click-modal="false"
    >
      <el-form :model="form" status-icon :rules="rules" ref="form" label-width="60px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="编号" prop="goodsCode">
              <el-input v-model="form.goodsCode" placeholder="物资编号" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="名称" prop="goodsName">
              <el-input v-model="form.goodsName" placeholder="物资名称" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="规格">
              <el-input v-model="form.goodsSpec" placeholder="规格" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="单位">
              <el-autocomplete
                :fetch-suggestions="querySearchUnit"
                v-model="form.goodsUnit"
                placeholder="单位"
                clearable
              ></el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="参考单价" label-width="80px">
              <el-popover ref="goodsPrice" placement="top-start" trigger="focus">
                <span style="color: teal;">{{form.goodsPrice|money}}</span>
              </el-popover>
              <el-input
                type="number"
                v-popover:goodsPrice
                v-model="form.goodsPrice"
                placeholder="参考单价"
                clearable
                @mousewheel.native.prevent
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="物资类别" label-width="70px">
              <type-cascader
                v-model="form.goodsTypeId"
                info="GoodsType"
                :canAdd="true"
                auth="Goods"
                @addClick="()=>{this.goodsTypeItem = {}}"
              ></type-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态">
              <el-switch
                v-model="form.isStop"
                active-color="red"
                inactive-color="green"
                active-text="停用"
                inactive-text="正常"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注">
          <el-input v-model="form.remark" placeholder="备注" type="textarea" :rows="3" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button type="info" @click="save(false)" :loading="loading">保存关闭</el-button>
            <el-button type="success" @click="save(true)" :loading="loading" v-if="!item.id">保存新增</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div>
      <add-type
        typeKind="GoodsType"
        :item="goodsTypeItem"
        @success="id=>{this.form.goodsTypeId=id}"
      ></add-type>
    </div>
  </div>
</template>

<script>
import TypeCascader from "@/components/TypeCascader";
import AddType from "@/components/BaseType/TypeItem";
export default {
  components: {
    TypeCascader,
    AddType
  },
  props: ["item"],
  data() {
    return {
      visible: false,
      loading: false,
      form: {},
      rules: {
        goodsCode: [this.$g.required],
        goodsName: [this.$g.required]
      },
      goodsTypeItem: {},
      unitArray: []
    };
  },
  watch: {
    item: {
      handler: function(value) {
        if (value.id) {
          this.loading = true;
          this.$get("Goods/Detail", {
            id: value.id
          })
            .then(r => {
              this.form = r;
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.reset(value.goodsTypeId);
        }
        this.visible = true;
      },
      deep: true
    }
  },
  methods: {
    reset(goodsTypeId) {
      this.getGoodsUnit();
      this.form = {
        goodsTypeId: goodsTypeId || null,
        isStop: false
      };
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
    },
    save(isContinue) {
      this.$refs["form"].validate(valid => {
        if (!valid) {
          return;
        }
        this.loading = true;
        let values = this.form;
        if (values.id) {
          this.$post("Goods/EditGoods", values)
            .then(r => {
              this.$message.success("操作成功！");
              this.$store.commit("updateBaseInfo", {
                info: "Goods",
                item: r
              });
              this.visible = false;
              this.$emit("success");
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$post("Goods/AddGoods", values)
            .then(r => {
              this.$message.success("操作成功！");
              this.$store.commit("addBaseInfo", {
                info: "Goods",
                item: r
              });
              if (isContinue) {
                this.reset();
              } else {
                this.visible = false;
              }
              this.$emit("success", r.id);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    getGoodsUnit() {
      this.$get("Goods/GetGoodsUnit").then(r => {
        this.unitArray = r;
      });
    },
    querySearchUnit(queryString, cb) {
      var unitArray = this.unitArray;
      var results = queryString
        ? unitArray.filter(item => {
            if (item.value.indexOf(queryString) >= 0) {
              return true;
            }
          })
        : unitArray;
      cb(results);
    }
  }
};
</script>

<style>
</style>